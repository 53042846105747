@import url('https://fonts.googleapis.com/css2?family=Jost:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Modak&display=swap');

$color-text: #333;
$color-accent: #e4612e;

@mixin display-font {
  font-family: 'Modak', sans-serif;
  font-weight: 400;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 2px;
}

body {
  font-family: 'Jost', sans-serif;
  line-height: 1.5em;
  color: $color-text;
}

#root {
  max-width: 1200px;
}

.main {
  margin-left: 16px;
  margin: auto 16px;
}

h1,
h2,
h3,
h4 {
  margin: 0;
  padding: 12px 0 6px 0;
  border: 0;
}

p {
  margin: 0;
  padding: 0 0 16px 0;
}

a {
  text-decoration: none;
  font-weight: 600;
  color: $color-text;
}

a:hover {
  text-decoration: $color-accent wavy underline;
}

ul {
  margin: 0;
  padding: 0 0 16px 0;
}

li {
  padding: 2px 0;
  list-style: none;
  text-decoration: none;
}

nav li {
  padding: 1px auto;
}

.inner {
  margin: 0px auto;
}

.content {
  margin-left: 700px;
}

.page-title {
  @include display-font;
  font-size: 2.25rem;
  font-weight: 400;
  margin-bottom: 8px;
}

.page-wrapper {
  padding-top: 24px;
  padding-bottom: 56px;
}

.title {
  @include display-font;
  font-size: 1.75rem;
  margin-bottom: 24px;
}

.stack-container {
  display: flex;
  flex-wrap: wrap;
  margin: 8px 0;
}

.stack {
  font-size: 0.75rem;
  margin: 0 4px 8px 0;
  padding: 4px 14px;
  border-radius: 32px;
  background-color: $color-accent;
  color: white;
  font-weight: 500;
  letter-spacing: 1px;
  width: fit-content;
  cursor: default;
  text-transform: uppercase;
  transition: all 0.2s;
  user-select: none;
}

.stack:hover {
  border-radius: 50%;
}

@media only screen and (min-width: 600px) {
  #root {
    display: flex;
  }
  .main {
    margin: 0;
    width: 65%;
  }
  .inner {
    margin-right: 32px;
  }
  .page-wrapper {
    padding-left: 48px;
    padding-top: 64px;
  }
}
