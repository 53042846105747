.project-card {
  border: 2px solid #e4612e;
  padding: 16px;
  margin: 8px 0 20px 0;
  border-radius: 16px;
}

.project-image-container {
  width: inherit;
  height: 30%;
}

.project-image {
  width: 100%;
  height: auto;
  object-fit: contain;
  border-radius: 10px;
}

.project-title {
  padding-top: 2px;
}

.bold-span {
  font-weight: 800;
}

.experience-date {
  color: grey;
}
