* {
  box-sizing: border-box;
  margin: 0;
}

body {
  /* margin: 0; */
  display: flex;
  justify-content: center;
}
