@use 'App';

nav a {
  font-weight: 400;
}

.active {
  font-weight: 800;
}

.sidebar {
  padding-top: 56px;
  margin-left: 16px;
  display: flex;
  flex-direction: column;
}

.facts {
  margin-top: 16px;
  display: flex;
  flex-wrap: wrap;
}

.profile-picture {
  width: 120px;
  height: auto;
  margin-bottom: 12px;
}

@media only screen and (min-width: 600px) {
  .sidebar {
    max-width: 35%;
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    align-self: flex-start;
    height: 90vh;
    overflow-y: scroll;
    margin-left: 32px;
  }
}
